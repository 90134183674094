import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import ko from './ko.json'

const allLocales = {
  en,
  es,
  fr,
  ko,
}

export default allLocales
